
import { defineComponent, onMounted, reactive, watch } from "vue";
//import MixedWidget4 from "@/components/widgets/mixed/Widget7.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

import moment from "moment";
import store from "@/store";

export default defineComponent({
  name: "CZCO2Emmition",

  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("CO2 Emmition", ["Market Insight"]);
    });

    // const series = reactive([
    //   {
    //     name: "ECX EUA DEC0/d",
    //     data: [],
    //   },
    //   {
    //     name: "ECX EUA DEC1/d",
    //     data: [],
    //   },
    //   {
    //     name: "ECX EUA DEC2/d",
    //     data: [],
    //   },
    // ]);

    const chartOptions = {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
          formatter: function (val, timestamp) {
            return moment(new Date(timestamp)).format("MMM DD");
          },
          style: {
            colors: "#9899ac",
          },
        },

        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: "#9899ac",
          },
          offsetX: 0,
        },
        title: {
          text: "£",
          style: {
            fontSize: "15px",
            fontWeight: 600,
          },
        },
      },
      grid: {
        show: false,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      legend: {
        position: "top",
      },
    };

    // async function getContent() {
    //   ApiService.get("api/Gasprices/getGaspricesMetrics/2017/0/0/")
    //     .then(async ({ data }) => {
    //       const response = data.Message;
    //       series[0].data = response.map((a) => [
    //         new Date(a.timeStamp),
    //         a.dayAhead,
    //       ]);
    //       series[1].data = response.map((a) => [new Date(a.timeStamp), a.q1]);
    //       series[2].data = response.map((a) => [new Date(a.timeStamp), a.q2]);
    //     })
    //     .catch(({ response }) => {
    //       // console.log(response);
    //     });
    // }

    const series = reactive([{}]);
    watch(
      () => store.getters.layoutConfig("toolbar.filterDate"),
      function () {
        getContent();
      }
    );

    /**
     *@description For getting Co2 emmition report
     */
    async function getContent() {
      //for Removing the old array before storing new values.
      series.splice(0);
      let year = store.getters.layoutConfig("toolbar.filterDate");
      let momentVariable = moment(year).format("YYYY");

      ApiService.get(
        "api/marketinsight/co2emission/getemission/" + momentVariable
      )
        .then(async ({ data }) => {
          const response = data;

          //For getting name values
          const headers = response[0];

          //For removing the first row headers from response so can map the fields
          response.splice(0, 1);
          series.push({
            name: headers.f2,
            data: response.map((a) => [new Date(a.f1), a.f2]),
          });
          series.push({
            name: headers.f3,
            data: response.map((a) => [new Date(a.f1), a.f3]),
          });
          series.push({
            name: headers.f4,
            data: response.map((a) => [new Date(a.f1), a.f4]),
          });
        })
        .catch(({ response }) => {
          // console.log(response);
        });
    }
    getContent();

    return {
      series,
      chartOptions,
    };
  },
});
